import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {Layers1}  from './Layers1';
import { Contact } from './pages/Contact';
import { Home } from './pages/Home';


function App() {
  return (
    <>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layers1><Home/></Layers1>}/>
        <Route path="/contact_us" element={<Layers1><Contact/></Layers1>}/>
      </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
