import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Style_global from './Style_global';
ReactDOM.render(
  <React.StrictMode>
    <Style_global/>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);


