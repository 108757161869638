
import styled from "styled-components";
import { Row, Col, Container } from 'react-bootstrap';
import { BsPeople } from "react-icons/bs";
import img3 from '../image/03.jpg';
import img4 from '../image/04.jpg';
import { BiMapPin,BiShield } from "react-icons/bi";
const Styled_ = styled.div`
      margin-top: 7%;
.all_imag_cover{
    position: relative;
    height: 445px;
}
.fr_img,.sc_img{
    position: absolute;
    
}
img.fr_img {
    z-index: 2;
    height: 268px;
    top: 190px;
    right: 10%;
    border-style: solid;
    border-width: 22px;
    border-color: white;
}
img.sc_img {
    width: 100%;
    min-width: 315px;
}
span.line::before {
    background-color: #dd0303;
    content: "";
    display: inline-block;
    height: 3px;
    position: relative;
    vertical-align: middle;
    width: 45px;
    margin: 7px;
}
h3.titel_about {

    font-size: 35px;
    color: #2e2e2e;
    margin: 25px 0;
    font-weight: 600;
}
p.des_about_ {
    font-size: 15px;
    color: #95919a;
}
.section_ep {
    position: absolute;
    bottom: 43px;
    font-size: 30px;
    color: #2e2e2e;
    text-transform: uppercase;
}
span.plus_text {
    color: #dd0303;
    font-weight: 700;
}
.icon_about_section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.icon_fg svg {
    width: 30px;
    height: 30px;
}
.icon_fg {
    width: 50px;
    height: 50px;
    background-color: #dd0303;
    padding: 10px;
    color: white;
    border-radius: 30px;
}
h3.icon_fg_title {
    margin: 14px 0;
    font-size: 18px;
    
}
p.icon_fg_des {
    font-size: 14px;
    color:#939393
}
.icon_about_section {
    margin-top: 40px;
}
a.btn_more_us {
    cursor: pointer;
    text-decoration: solid;
    padding: 15px 35px;
    background-color: #dd0303;
    color: white;
    border-radius: 35px;
    margin-top: 18px;
    font-size: 15px;
    position: absolute;
    transition: 250ms linear;
    border-width: 1px;
}
a.btn_more_us:hover{
    background-color: white;
    color: #dd0303;
    border-style: solid;
    border-width: 1px;

}
span.line {
    font-weight: 700;
}
@media only screen and (max-width: 920px) {
img.sc_img {
    min-width: 338px;
}
.title_about {
    margin-top: 40px;
    margin-bottom: 80px;
}
a.btn_more_us {
    margin: auto;
    width: 48%;
    text-align: center;
}
img.fr_img {
    z-index: 2;
    height: 268px;
    top: 190px;
    right: 0;
    border-style: solid;
    border-width: 22px;
    border-color: white;
}
}
@media only screen and (max-width:576px){
    a.btn_more_us {
        width: 91%;
    }
}
`;



const Section_about = () => {
    return <Styled_>
        <Container id="about">
            <Row>
                <Col sm={6} >
                <div className="all_imag_cover">
                <img className="fr_img" src={img3} alt="imag"/>
                <img className="sc_img" src={img4} alt="imag"/>
                <div className="section_ep">
                    <span className="plus_text">
                        +
                    </span>
                    20 années
                </div>
                </div>
                </Col> 
                <Col sm={6}>
                    <div className="title_about">
                        <span className="line">Bienvenue sur Hibacom</span>
                        <h3 className="titel_about">Des professionnels à votre écoute</h3>
                        <p className="des_about_">Nos implantations sur le territoire national offrent à nos équipes multi-techniques un grand périmètre opérationnel d’actions et de réactivité dans le déploiement de fibre optique et cela depuis plusieurs années <br/>
                        Nous vous accompagnons en tout point : de l’étude de faisabilité à la formation à l’utilisation de votre matériel, nous vous remettons une solution personnalisée et clé en main
                        </p>
                        <div className="icon_about_section">
                            <div className="fd_section">
                                <div className="icon_fg">
                                <BiMapPin/>
                                </div>
                                <h3 className="icon_fg_title">
                                Acteur local engagé 
                                </h3>
                                <p className="icon_fg_des">
                                présent sur toute la france avec des équipes opérationnelles qualifiés pour répondre à tous vos besoins 
                                </p>

                            </div>
                            <div className="fd_section">
                                <div className="icon_fg">
                                <BiShield/>
                                </div>
                                <h3 className="icon_fg_title">
                                Connecter les populations 
                                </h3>
                                <p className="icon_fg_des">
                                notre mission est de rapprocher les communautés afin de créer un écosystème fiable et sécurisé  
                                </p>

                            </div>

                        </div>
                        <a className="btn_more_us">
                        En savoir plus sur nous
                        </a>
                    </div>


                </Col>
            </Row>
        </Container>
    </Styled_>
        ;
};

export default Section_about;
