import styled from "styled-components";
import { Row, Col, Container } from 'react-bootstrap';
import { BsArrowRightShort } from "react-icons/bs";
import evolution from '../image/evolution.jpg';
import prevention from '../image/prevention.jpg';
import insertion from '../image/insertion.jpg';
import cooptation from '../image/cooptation.jpg';
import ffd from '../image/ert-technologies-rejoignez-nous.jpg';

import { Link  } from "react-router-dom";


const Styled_ = styled.div`
    margin-top: 7%;
    margin-bottom: 60px;
    span.line::before {
    background-color: #dd0303;
    content: "";
    display: inline-block;
    height: 3px;
    position: relative;
    vertical-align: middle;
    width: 45px;
    margin: 7px;
}

.sec_ob {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.dfg p.des_about_ {
    max-width: 560px;
    
}
.dfg  h3.titel_about {
    margin: auto;
}
.dfg .df_btn{
    margin: auto;  
}
a.df_btn {
    text-decoration: solid;

    font-size: 18px;
    background-color: #dd0303;
    color: #fff;
    padding: 11px 42px;
    border-radius: 30px;
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
}
figure {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 400px;
    height: 418px;
  border-radius: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  overflow: hidden;
}
figure:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
figure:hover img {
  transform: scale(1.25);
}
figure:hover figcaption {
  bottom: 0;
}
figure h1 {
  position: absolute;
  top: 50px;
  left: 20px;
  margin: 0;
  padding: 0;
  color: white;
  font-size: 60px;
  font-weight: 100;
  line-height: 1;
}
figure img {
  height: 100%;
  transition: 0.25s;
}
figure figcaption {
  position: absolute;
  bottom:  -50%;


  left: 0;
  width: 100%;
  margin: 0;
  padding: 30px;
  background-color:  rgb(221 3 3);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  color: white;
  line-height: 1;
  transition: 0.25s;
}
figure figcaption h3 {
  margin: 0 0 20px;
  padding: 0;
}
figure figcaption p {
  font-size: 14px;
  line-height: 1.75;
}
figure figcaption button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0 0;
  padding: 10px 30px;
  background-color: #1abc9c;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 14px;
}
span.line {
    font-weight: 700;
}
.dfg  p.des_about_ {
    color: #787878;
}
.section_all_work {
    margin-top: 40px;
}
.section_all_work a.icon-box-link {
    text-decoration: solid;
    font-size: 15px;
    font-weight: 600;
    color: #fff;
}
.section_all_work a.icon-box-link svg {
    width: 24px;
    height: 24px;
}
.titel_about {
    font-weight: 700;
    font-size: 35px;
    color: #2e2e2e;
    margin: 22px 0;
}
.sec_ob a {
  text-decoration: solid;
  text-align: center;
    padding: 15px;
    background-color: #dd0303;
    color: white;
    border-radius: 35px;
    margin-top: auto;
    font-size: 15px;
    /* position: absolute; */
    width: -webkit-fill-available;
    max-width: 231px;
    transition: 250ms linear;
}
.sec_ob a:hover{
  background-color: white;
    color: #dd0303;
    border-style: solid;
    border-width: 1px;
}
@media only screen and (max-width: 920px) {
figure {
  width: auto;
}
.sec_ob {
    flex-direction: column;
}
.sec_ob {
    text-align: center;
    
}
.sec_ob a{
  max-width: initial ;
}
figure figcaption {
    position: absolute;
     bottom: -62%;
}

}
@media only screen and (max-width:576px){
  figure figcaption {
    bottom: -57%;
    }
}
`;

const Section_projets = () => {
  return<Styled_>
      <Container id="projets" className="dfg">
      <span className="line">Nos métiers</span>
   <div className="sec_ob">
   <h3 className="titel_about">Ils nous ont rejoint, ils témoignent !</h3>
    <p className="des_about_">Nos équipes vous en parlent le mieux ! Découvrez leur histoire et leur expertise au sein d&apos;ERT Technologies. Des bureaux d&apos;études au terrain, découvrez ce qui anime nos collaborateurs et notre Président.</p>
    <Link to='/' className="btn_more_us">Découvrez nos métiers</Link>
   </div>
   <div className="section_all_work">

<Row>
    <Col xs={12} md={6} lg={4}>

    <figure className="image-block">
	<img src={evolution} alt="" />
	<figcaption>
		<h3>
        Évolution
		</h3>
		<p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.</p>
        <a>En savoir plus<BsArrowRightShort/></a>
	</figcaption>
</figure>
    </Col>
    <Col xs={12}  md={6} lg={4}>
    <figure className="image-block">
	<img src={prevention} alt="" />
	<figcaption>
		<h3>
        Prévention
		</h3>
		<p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.</p>
       <a>En savoir plus<BsArrowRightShort/></a>
	</figcaption>
</figure>
    </Col>
    <Col xs={12}  md={6} lg={4}>
    <figure className="image-block">
	<img src={insertion} alt="" />
	<figcaption>
		<h3>
        Insertion Professionnelle
		</h3>
		<p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.</p>
        <a>En savoir plus<BsArrowRightShort/></a>
	</figcaption>
</figure>
    </Col>

    <Col xs={12}  md={6} lg={4}>
    <figure className="image-block">
	<img src={ffd} alt="" />
	<figcaption>
		<h3>
        Votre carrière
		</h3>
		<p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.</p>
        <a>En savoir plus<BsArrowRightShort/></a>
	</figcaption>
</figure>
    </Col>
    <Col xs={12} md={6} lg={4}>
    <figure className="image-block">
	<img src={cooptation} alt="" />
	<figcaption>
		<h3>
        Prime de Cooptation
		</h3>
		<p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.</p>
       <a>En savoir plus<BsArrowRightShort/></a>
	</figcaption>
</figure>
    </Col>
  </Row>
   </div>
</Container>
  </Styled_>;
};

export default Section_projets;
