import { Navbar,Nav, Container,Offcanvas,Stack,NavDropdown,Button} from 'react-bootstrap';
import styled from 'styled-components';
import { AiOutlineBank } from "react-icons/ai";
import { BsTelephone,BsEnvelope } from "react-icons/bs";
import Icon_des from './Icon_des';
import logo from '../image/logo.png';
import {  Link } from 'react-router-dom';
import OffCanvas_1 from '../components/OffCanvas_1';


// style
const Styled_navbar = styled.div`
nav.nav_section {
  padding: 15px 0px;
}
.top_bar.hstack.gap-3 {
    margin-top: 25px;
    border-bottom-style: solid;
    border-width: 1px;
    border-color: #3132360f;
    padding-bottom: 18px;
}
.nav-link {
    margin: 0 20px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
}

a.nav-link:hover {
    color: #dd0303  !important;
}
.btn_red {
  border-radius: 30px;
    background-color: #dd0303;
    border: none;
    padding: 15px 35px;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}
img.logo_img {
    max-height: 70px;
}
.fg_mobil{
  display: none;
}

button.navbar-toggler.collapsed {
    border: none;
    box-shadow: none;
}


  @media only screen and (max-width: 920px) {
    .top_bar{
    display: none;
  }
  .nav-link {
    color: #4e4e4e !important;
    font-weight: 500;
}
  .fg_mobil{
  display: block;
}
  }

`;
const Navbar_1 = () => {
  return <Styled_navbar>
    <Container>
    <Stack className='top_bar' direction="horizontal" gap={3}>
     <Link to="/"><img  className='logo_img' src={logo} alt="me" /></Link>
     <div className=" ms-auto">
     <Icon_des icon={<AiOutlineBank/>} titel ="Adresse" descreption = "17 rue de rosheim 67000 Strasbourg" />
     </div>
      <div className=""><Icon_des icon={<BsTelephone/>} titel ="Numéro de téléphone" descreption = "+33 7 69 84 94 41" des="+33 7 49 62 80 66" /></div>
      <div className=""><Icon_des icon={<BsEnvelope/>} titel ="Envoyez votre e-mail" descreption = "contact@hibacom.fr" /></div>
    </Stack>
   <Navbar className='nav_section' expand="lg">
    <Navbar.Collapse id="basic-navbar-nav">
    <Nav className="me-auto">
    <Nav.Link as={Link} to="/" >Accueil</Nav.Link>
    <Nav.Link  href='#about' >À propos de nous</Nav.Link>
    <Nav.Link  href="#Services" >Services</Nav.Link>
    <Nav.Link  href="#projets">Nos métiers</Nav.Link>
    </Nav>
    <Nav>
    <Link to="/contact_us"><Button className='btn_red' variant="secondary" size="sm">Contactez-nous </Button></Link> 
    </Nav>
  </Navbar.Collapse>
</Navbar>
<Navbar expand={false} className='fg_mobil'>
  <Container fluid>
    <Navbar.Brand as={Link} to="/" ><img  className='logo_img' src={logo} alt="me"  /></Navbar.Brand>
    <Navbar.Toggle aria-controls="offcanvasNavbar" />
    <Navbar.Offcanvas
      id="offcanvasNavbar"
      aria-labelledby="offcanvasNavbarLabel"
      placement="end"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title id="offcanvasNavbarLabel">HIBACOM</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Nav className="justify-content-end flex-grow-1 pe-3">
        <Nav.Link  as={Link} to="/" >Accueil</Nav.Link>
       <Nav.Link  href='#about' >À propos de nous</Nav.Link>
       <Nav.Link  href="#Services" >Services</Nav.Link>
       <Nav.Link  href="#projets">Nos métiers</Nav.Link>
       <Nav.Link as={Link} to="/contact_us" >Contactez-nous</Nav.Link>
        </Nav>
      </Offcanvas.Body>
    </Navbar.Offcanvas>
  </Container>
</Navbar>

</Container>
</Styled_navbar>;

};

export default Navbar_1;
