import { createGlobalStyle } from 'styled-components';
 
const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
  body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif !important;
  }
 a,h1,span,h2,h3,h4 {
    font-family: 'Poppins', sans-serif !important;
 }
 .nav-link {
    color: #4e4e4e !important;
    font-weight: 500;
}

`;
 
export default GlobalStyle;