
import styled from "styled-components";
import { Row, Col, Container } from 'react-bootstrap';
import im01 from '../image/01.jpg';
import logo_footer from '../image/logo_footer.png';
import { FiFacebook,FiInstagram,FiTwitter,FiLinkedin } from "react-icons/fi";
const Styled_ = styled.div`
    margin-top: 7%;
    background-image: url(${im01});
    background-repeat: no-repeat;
    background-size: cover;
    .footer_se {
    background-color: #161616f2;
    padding-top: 70px;
}
.footer_fr img {
    width: 200px;
}
.footer_fr p,.list_footer p {
    color: #858585;
    font-size: 16px;
    margin: 30px 0px;
}
.soi_medai {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 180px;
}
a.medai_ svg {
    width: 23px;
    height: 20px;
}
a.medai_{
    padding: 5px;
    background-color: #dd0303;
    color: #fff;
    border-radius: 20px;
    cursor: pointer;
    width: 35px;
    height: 35px;
}
.list_footer {
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.list_footer h3 {
    border-bottom-style: solid;
    padding-bottom: 10px;
    max-width: fit-content;
    border-color: #dd0303;
    border-width: 1px;
    font-size: 20px;
    font-weight: 700;
}
.list_footer a {
    cursor: pointer;
    font-size: 14px;
    margin: 5px 0;
    color: #fff;
    text-decoration-line: blink;
}
.Bulletin input {
    height: 45px;
    width: 255px;
    border: none !important;
    padding: 0 10px;
}
button.jkit-mailchimp-submit {
    height: 45px;
    width: 65px;
    background-color: #dd0303;
    border: none;
    color: #fff;
}
.footer_copy_r h4 {
    font-size: 14px;
    color: #858585;
    font-weight: 300;
}
.footer_copy_r {
    padding-top: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.list_footer a:hover {
    color: red !important;
}
.soi_medai a:hover {
    background-color: white;
}
.soi_medai a:hover svg {
    color: #dd0303 !important;
}
@media only screen and (max-width: 920px) {
.footer_fr {
    text-align: center;
    margin-bottom: 65px;

}
.soi_medai {
    margin: auto;
    transition: 250ms linear;
}
.list_footer p ,.Bulletin{
    text-align: center;
}

}
`;

const Footer = () => {
  return <Styled_>
      <div className="footer_se">
      <Container>
    <Row>
        <Col sm={4}>
            <div className="footer_fr">
                <img src={logo_footer} alt="footer_logo"/>
                <p>Faites confiance à une équipe professionnelle à votre service</p>
                <div className="soi_medai">
                    <a href="https://www.facebook.com/HIBA-COM-112295831368659" className="medai_"><FiFacebook/></a>
                    <a href="#" className="medai_"><FiInstagram/></a>
                    <a href="#" className="medai_"><FiTwitter/></a>
                    <a href="#" className="medai_"><FiLinkedin/></a>
                </div>
            </div>

        </Col>
        <Col sm={4}>
            <div className="list_footer">
                <h3>
                Liens rapides
                </h3>
                <a href="#">Accueil</a>
                <a href="#">À propos de nous</a>
                <a href="#">Services</a>
                <a href="#">Projets</a>
            </div>

        </Col>
        <Col sm={4}>
        <div className="list_footer">
                <h3>
                Liens utiles
                </h3>
                <a href="#">Politique de confidentialité</a>
                <a href="#">Termes et conditions</a>
                <a href="#">Support</a>
                <a href="#">FAQ</a>
            </div>
        </Col>

    </Row>
    <div className="footer_copy_r">
        <h4>Hibacom</h4>
        <h4>Copyright © 2022. All rights reserved.</h4>
    </div>
</Container>
      </div>


  </Styled_>;
};

export default Footer;
