import React,{useState} from 'react';
import styled from 'styled-components';
import { Row, Container, Col, Button, Form } from 'react-bootstrap';
import axios from 'axios';
const Styled_ = styled.div`
.section_ly_titel_page {
    margin: auto;
    text-align: center;
    padding: 200px 0px;
    background-color: #1e1d1e;
    color: #fff;
    text-transform: uppercase;
}
span.line {
    font-weight: 700;
    color: #2e2e2e;
}
span.line::before {
    background-color: #dd0303;
    content: "";
    display: inline-block;
    height: 3px;
    position: relative;
    vertical-align: middle;
    width: 45px;
    margin: 7px;
}
h3.titel_about {
    font-size: 35px;
    color:#2e2e2e;
    margin: 10px 0;
}
.titil_des_section {
    padding-top: 60px;
}
.form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #dd030366;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(221 3 3 / 0%);
}
input {
    height: 50px;
    border-color: #1e1d1e33;
    border-width: 2px !important;
}
textarea{
    border-color: #1e1d1e33;
    border-width: 2px !important;
}
form.form_contect {
    padding: 50px 0;
}
label.form-label {
    font-size: 18px;
    font-weight: 500;
    color: #1e1d1ec7;
}
.dfss {
    width: 100%;
    margin: auto;
}
button.btn_ds.btn.btn-primary {
    width: 250px;
    margin: auto;
    padding: 10px;
    text-align: center;
    float: right;
    background-color: #dd0303b3;
    border: aliceblue;
    transition: 100ms linear; 
}
button.btn_ds.btn.btn-primary:hover {
    padding: 15px;
    background-color: white;
    color: #dd0303;
    border-color: #dd0303 !important;
    border-width: 1px;
    border-style: solid;
}
.dfss.row h3 {
    text-align: center;
    padding: 10px 0;
    color: #198754;
    font-size: 18px;
    max-width: 430px;
    margin: auto;
}
`;
export const Contact = () => {
    const [validated, setValidated] = useState(false);
    const [finished,setfinished] =  useState(false);
    const [data_form , setdata_form] = useState({
        email:'',
        name:'',
        phone:'',
        subject:'',
        message:''
        
    });

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        console.log(form.checkValidity());
        if (form.checkValidity() !== false) {
            setValidated(true);
            axios.post(`https://api.hibacom.fr/sander.php`,JSON.stringify(data_form),{
                headers: {
                  // Overwrite Axios's automatically set Content-Type
                  'Content-Type': 'application/json'
                }
              })
      .then(res => {
        console.log(res.data['state']);
        if(res.data['state']){
            setfinished(true);
        }
      });
            console.log(data_form);
        }
        
      };

    return <Styled_>
        <div className='section_ly_titel_page'>
            <h1>Contactez-nous</h1>
        </div>
        <Container>
            <div className="titil_des_section">
                <span className="line">Contactez-nous !</span>
                <h3 className="titel_about">Vous informer et échanger avec nous ne vous coûtera rien</h3>
            </div>
            <Form className='form_contect'  validated={validated} onSubmit={handleSubmit}>
                <Row>
                    <Col sm={6}>
                        <Form.Group className="mb-3" controlId="formBasicname">
                            <Form.Label>Nom & Prénom *</Form.Label>
                            <Form.Control  onChange={e => setdata_form({ ...data_form,name:e.target.value})} value={data_form.name} required type="text" />
                            <Form.Control.Feedback type="invalid">Ce Champ est obligatoire</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                    <Form.Group className="mb-3" controlId="formBasicemail">
                            <Form.Label>Email *</Form.Label>
                            <Form.Control   onChange={e => setdata_form({ ...data_form,email:e.target.value})} value={data_form.email}  required  type="email" />
                            <Form.Control.Feedback type="invalid">Ce Champ est obligatoire</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                    <Form.Group className="mb-3" controlId="formBasicphone">
                            <Form.Label>Téléphone *</Form.Label>
                            <Form.Control   onChange={e => setdata_form({ ...data_form,phone:e.target.value})} value={data_form.phone} required type="text" />
                            <Form.Control.Feedback type="invalid">Ce Champ est obligatoire</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                    <Form.Group className="mb-3" controlId="formBasicSujet">
                            <Form.Label>Sujet *</Form.Label>
                            <Form.Control    onChange={e => setdata_form({ ...data_form,subject:e.target.value})}  value={data_form.subject} required type="text" />
                            <Form.Control.Feedback type="invalid">Ce Champ est obligatoire</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col sm={12}>
                    <Form.Group className="mb-3" controlId="formBasicMessage">
                        <Form.Label>Message</Form.Label>
                        <Form.Control as="textarea" onChange={e => setdata_form({ ...data_form,message:e.target.value})}  value={data_form.message} rows={6} />
                    </Form.Group>
                    </Col>
                </Row>
                <div className='dfss row'>
                    {finished?<h3>Envoyé avec succès</h3>:<div><Button className='btn_ds' type="submit">Envoyer</Button></div>}
              </div>
            </Form>
        </Container>
    </Styled_>;
};
