import React from 'react';
import styled from 'styled-components';
import { Row, Col, Container,Carousel } from 'react-bootstrap';
const StyledDIV = styled.div`

margin-top: 260px;
span.line {
    font-weight: 700;
}
span.line::before {
    background-color: #dd0303;
    content: "";
    display: inline-block;
    height: 3px;
    position: relative;
    vertical-align: middle;
    width: 45px;
    margin: 7px;
}
.titel_about {
    font-weight: 700;
    font-size: 35px;
    color: #2e2e2e;
    margin: 22px 0;
}
 p.des_about_ {
    color: #787878;
}
 p.des_about_ {
    max-width: 560px;
}
.section_im {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.section_tim {
    margin: auto;
    max-height: 257px;
    height: 257px;
}

.section_tim {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.info_user {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
a.carousel-control-prev,a.carousel-control-next {
    display: none;
}
.info_user img {
    width: 70px;
    border-radius: 69px;
    object-fit: contain !important;
}
.info_user h3 {
    font-size: 16px;
    margin-left: 12px;
    text-transform: uppercase;
}

.section_tim p {
    font-style: italic;
    font-size: 20px;
    font-weight: 300;
}
.carousel-dark .carousel-indicators [data-bs-target] {
    background-color: #dd0303;
}
@media only screen and (max-width: 920px) {
margin-top: 38px;

}
@media only screen and (max-width:576px){
.titil_des_section {
    text-align: center;
}
 .section_tim p {
    font-style: italic;
    font-size: 16px;
}
}
`;

const Section_p = () => {
  return <StyledDIV>
      <Container>
          <Row>
              <Col xs={12}  md={6}>
              <div className="titil_des_section">
                <span className="line">Témoignages</span>
                <h3 className="titel_about">Ils nous ont rejoint, ils témoignent Installateur senior</h3>
                <p className="des_about_">l’un des métiers les plus important de la société avec une grande expérience demandée </p>
                </div>

              </Col>
              <Col xs={12}  md={6}>
              <Carousel variant="dark">
                  <Carousel.Item>
                      <div className='section_tim'>
                       <p>Leader de sa team, il synchronise différents collaborateurs pour le bon déroulement des projets  </p>
                       <div className='section_im'>
                           <div className='info_user'>
                           <img src='https://templatekit.jegtheme.com/electriza/wp-content/uploads/sites/112/2021/06/testimonial-YV9ABZR.png' alt='' />
                            <h3>Chef d’équipe </h3>
                           </div>
                       </div>
                      </div>
                  </Carousel.Item>
                  <Carousel.Item>
                      <div className='section_tim'>
                       <p>Assure l'élaboration de la stratégie en amont de chaque installation et garantit son suivi</p>
                       <div className='section_im'>
                           <div className='info_user'>
                           <img src='https://templatekit.jegtheme.com/electriza/wp-content/uploads/sites/112/2021/06/testimonial-83S5W35.jpg' alt='' />
                            <h3>Programmeur système</h3>
                           </div>
                       </div>
                      </div>
                  </Carousel.Item>

                  
               </Carousel>

              </Col>
          </Row>
      </Container>


  </StyledDIV>;
};
export default Section_p;
