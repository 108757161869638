
import styled from "styled-components";
import { Row, Col, Container } from 'react-bootstrap';
import W_icon_des from "./W_icon_des";
import { AiOutlineBank } from "react-icons/ai";
import imag5 from '../image/05.jpg';

import { BiTrip,BiNetworkChart,BiWrench } from "react-icons/bi";
const Styled_ = styled.div`
    background-image: url('${imag5}');
    background-position: center;
    margin-top: 10%;
    background-repeat: repeat;
    background-size: cover;
     span.line::before {
    background-color: #dd0303;
    content: "";
    display: inline-block;
    height: 3px;
    position: relative;
    vertical-align: middle;
    width: 45px;
    margin: 7px;

}
span.line {
    color: #ffffff;
}
h3.titel_about {
    font-size: 35px;
    color: #ffffff;
    margin: 10px 0;
}
p.des_about_ {
    font-size: 15px;
    color: #b3b3b3;
}
.titil_des_section {
    text-align: center;
}
.section_ser_icon.row {
    margin-bottom: 80px;
    position: relative;
    margin-top: 80px;
}
.section_df {
    height: 100%;
    padding: 100px 8%;
    background-color: #000000bf;
    position: relative;
}
a.icon-box-link:hover {
    padding-left: 16px;
}
a.icon-box-link{
    transition: 250ms linear;
}

.section_color {
    position: absolute;
    bottom: -5%;
    padding: 30px 16px;
    background-color: #dd0303;
    width: 90%;
    right: 0;
}

.sec_ {
    text-align: center;
    color: #fff;
}
.num_sec_ {
    font-size: 40px;
    font-weight: 700;
}
.num_des {
    font-size: 20px;
    font-weight: 500;
}
span.line {
    font-weight: 700;
}
@media only screen and (max-width: 920px) {
    .section_df {
    padding: 100px 0%;
    }
.section_color {
    position: absolute;
    bottom:  -32%;
    padding: 30px 16px;
    background-color: #dd0303;
    width: 100%;
    right: 0;
}
.sec_ {
    text-align: center;
    color: #fff;
    margin: 22px;
}

.section_df {
    margin-bottom: 515px;
}
}
`;
const Section_services = () => {
    return <Styled_>
        <div className="section_df">
            <Container id="Services">
                <div className="titil_des_section">
                <span className="line">Notre savoir-faire</span>
                <h3 className="titel_about">Découvrez Notre savoir-faire</h3>
                <p className="des_about_">Nos équipes implantées sur l'ensemble du territoire conçoivent, construisent et exploitent aussi bien des réseaux en câbles à fibre optique qu'en cuivre.</p>
                </div>
                <Row className="section_ser_icon">
                    <Col sm={4} md={6} lg={4} className="">
                    <W_icon_des icon={<BiTrip/>} url="/" titel ="TRANSPORT" descreption = {<div><ul>
                        <li> Tirage</li>
                        <li>Intervention NRO</li>
                        <li>Raccordement fibre optique</li>
                        <li>Pose de boîtiers de piquage</li>
                        <li>Pose de points d'aboutement</li>
                        <li>Raccordement PMZ avec préparation des différents câbles</li>
                        <li>Pose de PMZ, PA, BPI, BE</li>
                        </ul></div>} />
                    </Col>
                    <Col sm={4} md={6} lg={4} className="">
                    <W_icon_des icon={<BiNetworkChart/>} url="/"  titel ="RACCORDEMENT ABONNÉS" descreption = {<div><ul>
                        <li> Installation de prise optique </li>
                        <li>Mise en service des équipements (box, décodeur) </li>
                        <li>Tests du bon fonctionnement de la fibre</li>
                        </ul></div>

                    } />
                    </Col>
                    <Col sm={4} md={6} lg={4} className="">

                    <W_icon_des icon={<BiWrench/>} url="/"  titel ="MAINTENANCE RÉSEAUX" descreption = {<div><ul>
                        <li>Maintien de la vie du réseau de télécommunications</li>
                        <li>Intervenir en cas de panne</li>
                        <li>VTL : Verticalisé (Transport)</li>
                        <li>HTZ : Horizontale Immeuble</li>
                        
                        </ul></div>} />
                    </Col>
                    </Row>
                    <div className="section_color">
                    <Row>
                    <Col sm={3}  md={6} lg={3}  className="">
                        <div className="sec_">
                            <div className="num_sec_">
                                20 <span>+</span>
                            </div>
                            <div className="num_des">
                               Des années d&apos;expérience
                            </div>
                        </div>
                    </Col> 
                    <Col sm={3}  md={6} lg={3} className="">
                    <div className="sec_">
                            <div className="num_sec_">
                            2,234 <span>+</span>
                            </div>
                            <div className="num_des">
                            Projets terminés
                            </div>
                        </div>
                    </Col> 
                    <Col sm={3}  md={6} lg={3} className="">
                    <div className="sec_">
                            <div className="num_sec_">
                            1,429 <span>+</span>
                            </div>
                            <div className="num_des">
                            Clients satisfaits
                            </div>
                        </div>
                    </Col> 
                    <Col sm={3}  md={6} lg={3} className="">
                    <div className="sec_">
                            <div className="num_sec_">
                            64 <span>+</span>
                            </div>
                            <div className="num_des">
                            Equipe professionelle
                            </div>
                        </div>
                    </Col> 
                    </Row>
                    </div>


            </Container>

        </div>



    </Styled_>;
};

export default Section_services;
