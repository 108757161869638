import React from 'react';
import Slider_first from '../components/Slider_first';
import Section_about from '../components/Section_about';
import Section_services from '../components/Section_services';
import Section_projets from '../components/Section_projets';
import Section_p from '../components/Section_p';
import Section_h from '../components/Section_h';

export const Home = () => {
  return <>
  <Slider_first/>
  <Section_about/>
  <Section_services/>
  <Section_projets/>
  <Section_h/>
  <Section_p/>
  </>;
};
