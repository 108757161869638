import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import { FaMedal,FaConnectdevelop,FaHands,FaBookReader,FaGetPocket } from "react-icons/fa";
import img from '../image/Artboard 4.jpg'
import W_icon_des from './W_icon_des';

const StyledDIV = styled.div`
 background-image:url('${img}');
 .rf {
    background-color: #1e1010e6;
    padding: 100px 0;
    
}
.rf .container {
    position: relative;
}
.titel_about {
    font-weight: 700;
    font-size: 35px;
    color: #ffffff;
    margin: 22px 0;
}
p.des_about_ {
    max-width: 840px;
    color: #d1d1d1;
    padding: 0 60px;
}
.sec_ob {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: max-content;
    align-items: center;
}
.imag_se img {
    max-width: 650px;
}
.imag_se {
    position: absolute;
    top: 425px;
    right: -190px;
}
@media only screen and (max-width: 920px) {
  
    .sec_ob {
    width: fit-content;
    flex-direction: column;
    }
    p.des_about_ {
    padding: 0;
    }
.imag_se {
    position: absolute;
    top: 300px;
    right: 0;
    display: none;
}
}
.section_info_f {
    margin-top: 50px;
}
.iNxCwT .icon_ svg {
    width: 40px;
    height: 40px;
}
`;

const Section_h = () => {
  return <StyledDIV>
      <div className='rf'>
      <Container >
             <Row>
              <Col xs={12}  lg={12}>
    <div className="sec_ob">
   <h3 className="titel_about">Ils nous ont rejoint, ils témoignent !</h3>
  
  
   </div>
   <div className='section_info_f'>
       <Row>
           <Col xs={12}   md={4}>
           <W_icon_des icon={<FaMedal/>}  titel ="Evolution" descreption = "Pour attirer de jeunes talents ainsi que de nouveaux profils et compétences, alignés avec l’évolution stratégique actuelle, Hibacom souhaite poursuivre et renforcer ses programmes de recrutement et de développement ainsi que  la promotion de l’alternance et des stages." />
           </Col >
           <Col xs={12}  md={4}>
           <W_icon_des icon={<FaConnectdevelop/>}  titel ="Développement" descreption = "Hibacom place la promotion et la mobilité internes au cœur de son métier, au travers notamment de nouveaux programmes de développement multi formats et multi métiers." />
           </Col>
           <Col xs={12}  md={4}>
           <W_icon_des icon={<FaHands/>}  titel ="Diversité" descreption = "Depuis sa création, Hibacom s’est engagé très tôt dans la promotion de la diversité,de nombreux dispositifs ont été mis en place afin de favoriser et promouvoir l’égalité femmes-hommes, de lutter contre les violences faites aux femmes, de promouvoir l’évolution des personnes en situation de handicap." />
           </Col>
           <Col xs={12}  md={4}>
           <W_icon_des icon={<FaBookReader/>}  titel ="Formation" descreption = "La formation constitue une priorité pour Hibacom. Chaque année, plus de onze heures de formation sont dispensées aux collaborateurs en moyenne dans toutes les régions d'implantation en plus des formations règlementaires, telles que celles sur la santé et la sécurité" />
           </Col>
           <Col xs={12}  md={4}>
           <W_icon_des icon={<FaGetPocket/>}  titel ="Management" descreption = "La manière dont nous construisons de la valeur ensemble change radicalement à l’ère du digital, et le management doit donc s’adapter rapidement. Chez Hibacom, une attention particulière est portée à la montée en compétences des managers et de leurs équipes. Des programmes innovants et engageants sont en place" />
           </Col>
       </Row>
   </div>
            </Col>
              <Col xs={12}  md={6}>
              <div className='imag_se'>
                  <img src={img} alt='dfg'/>

              </div>
              </Col>
            </Row>
        </Container>
      </div>

  </StyledDIV>;
};

export default Section_h;
