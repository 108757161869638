import React from 'react';
import Navbar_1 from './components/Navbar_1';
import Footer from './components/Footer'
export const Layers1 = (props) => {
  return <>
  <Navbar_1/>
  
  {props.children}
  <Footer/>
  </>;
};
