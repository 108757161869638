import styled from "styled-components";
import { BsArrowRightShort } from "react-icons/bs";

// style
const Styled_ = styled.div`
.icon_des {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: flex-start;
}
.icon_ svg {
    width: 50px;
    height: 50px;
}
.icon_ {
    margin: 10px;
    background-color: #dd0303;
    padding: 10px;
    border-radius: 60px;
    color: #fff;
}
h4.titel_ {
  
    font-size: 20px;
    margin: 10px 0;
    color: #fff;
    font-weight: 700;

}
span.sub_titel {
    font-size: 14px;
    color: #b3b3b3;
    font-weight: 600;
   
    max-width: 300px;

  
}
.des_ {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
a.icon-box-link {
    text-decoration: solid;
    font-size: 14px;
    font-weight: 700;
    margin: 10px 0;
    color: #dd0303;
}
a.icon-box-link svg {
    width: 26px;
    height: 26px;
}
`;

const W_icon_des = (props) => {
  return <Styled_>
           <div className='icon_des'>
       <div className='icon_'>
       {props.icon}
       </div>
        <div className='des_'>
         <h4 className='titel_'>{props.titel}</h4>
         <span className='sub_titel'>{props.descreption}</span>
         {
         !props.url==""?<a href={props.url} className="icon-box-link">En savoir plus <BsArrowRightShort/> </a>:<></>
         
         }
         
       </div>
       </div>

  </Styled_>;
};

export default W_icon_des;
