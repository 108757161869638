import { useState } from 'react';
import { Offcanvas,Nav } from 'react-bootstrap';
import styled from 'styled-components';
// style
const Styled_navbar = styled.div`
nav.nav_section {
    padding: 40px 0px;
}
`;
const OffCanvas_1 = ({ name, ...props }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return <Styled_navbar><Nav.Link  onClick={handleShow} className="me-2">
    {name}
  </Nav.Link>
  <Offcanvas show={show} onHide={handleClose} {...props}>
    <Offcanvas.Header closeButton>
      <Offcanvas.Title>Offcanvas</Offcanvas.Title>
    </Offcanvas.Header>
    <Offcanvas.Body>
      Some text as placeholder. In real life you can have the elements you
      have chosen. Like, text, images, lists, etc.
    </Offcanvas.Body>
  </Offcanvas></Styled_navbar>;
};

export default OffCanvas_1;
