import styled from "styled-components";

// style
const Styled_ = styled.div`
.icon_des {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
}
.icon_ svg {
    width: 30px;
    height: 30px;
}
.icon_ {
    margin: 10px;
    background-color: #dd0303;
    padding: 10px;
    border-radius: 30px;
    color: #fff;
}
h4.titel_ {
    font-size: 16px;
    margin: 0;
}
span.sub_titel,span.des_title {
    font-size: 12px;
    color: #000000;
    font-weight: 500;
}
.des_ {
    display: flex;
    flex-direction: column;
}
`;

const Icon_des = (props) => {
  return <Styled_>
           <div className='icon_des'>
       <div className='icon_'>
       
       {props.icon}
       </div>
        <div className='des_'>
         <h4 className='titel_'>{props.titel}</h4>
         <span className='sub_titel'>{props.descreption}</span>
         <span className="des_title">{props.des}</span>
       </div>
       </div>

  </Styled_>;
};

export default Icon_des;
