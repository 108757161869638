import styled from "styled-components";
import { Row,Container,Col,Carousel } from 'react-bootstrap';
import { AiOutlineCaretRight } from "react-icons/ai";

import img01 from "../image/Artboard 1.jpg"
import img02 from "../image/Artboard 2.jpg"
import img03 from "../image/Artboard 3.jpg"
import img04 from "../image/Artboard 4.jpg"
import img05 from "../image/Artboard 5.jpg"
const Styled_ = styled.div`
.section_imag_half {
    width: 100%!important;
    max-height: 630px;
    margin: 0;
    background-repeat: no-repeat;
    background-size: cover;


}
.half01{
    background-image: url('${img01}');
}
.half02{
    background-image: url('${img02}');
}
.half03{
    background-image: url('${img03}');
}
.half04{
    background-image: url('${img04}');
}
.half05{
    background-image: url('${img05}');
}

.section_imag_half.half01 .col{
    background: linear-gradient(
90deg
,#2e2e2e   30%,#00ffff00 30%);
}

.df{
    max-height: 630px;
    height: 630px;
}
.box_des {
    position: relative;
    top: 90px;
    bottom: 0;
    left: 0;
    right: 0;
    width: 590px;
    margin-right: auto;
    background-color: white;
    height: auto;
    padding: 25px;
}
h1.text {
    font-size: 45px;
    color: #2e2e2e;
}
p.des_ {
    font-size: 16px;
    margin-top: 30px;
    color: #8e9197;
    
}
.btn_section {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 50px;
}
a.btn_contect_us {

    -webkit-text-decoration-line: blink;
    text-decoration-line: blink;
    background-color: #dd0303;
    margin-top: auto;
    padding: 15px 30px;
    border-radius: 35px;
    font-size: 15px;
    color: #fff;
    font-weight: 500;
    -webkit-transition: 500ms linear;
    transition: 500ms linear;
}
a.btn_vid {
    display: flex;
    margin-top: auto;
    flex-direction: row;
    justify-content: space-between;
    text-decoration-line: blink;
    transition: 500ms linear; 
}

.icon_d svg {
    height: 60px;
    width: 60px;
    padding: 15px;
    border-style: solid;
    border-radius: 35px;
    color: #dd0303;
    border-width: 1px;
}
.test_icon {
    margin: auto;
    padding-left: 15px;
    color: #dd0303;
    font-size: 15px;
    font-weight: 500;
}
a.btn_contect_us:hover,.btn_vid:hover {
    margin-bottom: 10px !important;
    cursor: pointer;

}
.box_des.fg_j {
    background-color: #2e2e2e;
}
.box_des.fg_j h1.text {
    color: white;
}

@media only screen and (max-width: 920px) {
.box_des {
    position: relative;
    top: 90px;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    margin-right: auto;
    background-color: white;
    height: auto;
    padding: 15px;
}
h1.text {
    font-size: 30px;
    color: #2e2e2e;
}
.btn_section {
    display: none !important;
}
.section_imag_half.half01 .col {
    background: none;
}
.carousel-control-next, .carousel-control-prev {
    width: 11%;
}
 .carousel-control-prev-icon, .carousel-control-next-icon {
    background-color: #ffffff00 !important;
}
}
.carousel-control-prev-icon,.carousel-control-next-icon {
    filter: grayscale(1) !important;
    background-color: black;
    padding: 22px;
    border-radius: 22px;
}

.carousel-dark .carousel-indicators [data-bs-target] {
    background-color: #fff;
}
.carousel-indicators button.active {
    background-color: #2e2e2e !important;
}
.carousel-control-next, .carousel-control-prev {
    width: 5%;
}
`;
const Slider_first = () => {
  return <Styled_>
<Carousel variant="dark">
  <Carousel.Item>
  <Row className="section_imag_half half01">
    <Col>
    <Container className="df">
        <div className="box_des">
        <h1 className="text">Déploiement de réseaux Fibre sur-mesure</h1>
        <p className="des_">Nous nous appuyons sur la maîtrise de l’ensemble des technologies de télécommunications et sur notre expérience dans la gestion de réseaux déjà existants et le déploiement de nouvelle structure.</p>
        <div className="btn_section">
         <a className="btn_contect_us">Contactez-nous</a>
         <a className="btn_vid"><div className="icon_d"><AiOutlineCaretRight/></div><div className="test_icon">Regarder la vidéo</div></a>
        </div>
        </div>
    </Container>
    </Col>
  </Row>
  </Carousel.Item>


  <Carousel.Item>
  <Row className="section_imag_half half02">
    <Col>
    <Container className="df">
        <div className="box_des fg_j">
        <h1 className="text">Installation pour les particuliers et les professionnels</h1>
        <p className="des_">Nos équipes sont là pour vous accompagner que vous soyez particuliers ou professionnelles à l’aide des différents processus établis en amont avec vous. 	</p>
        </div>
    </Container>
    </Col>
  </Row>
  </Carousel.Item>
  <Carousel.Item>
  <Row className="section_imag_half half03">
    <Col>
    <Container className="df">
        <div className="box_des fg_j">
        <h1 className="text">Trouvez l’offre qui vous convient</h1>
        <p className="des_">Nous entreprenons l’installation des réseaux télécom nationaux, régionaux et locaux, et intervenons jusque chez l’abonné pour le raccordement, la mise en service ou encore le dépannage.
	</p>
        </div>
    </Container>
    </Col>
  </Row>
  </Carousel.Item>
  <Carousel.Item>
  <Row className="section_imag_half half04">
    <Col>
    <Container className="df">
        <div className="box_des fg_j">
        <h1 className="text">Nous sommes dédiés à l&apos;excellence dans les travaux</h1>
        <p className="des_">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim	</p>

        </div>
    </Container>
    </Col>
  </Row>
  </Carousel.Item>
  <Carousel.Item>
  <Row className="section_imag_half half05">
    <Col>
    <Container className="df">
        <div className="box_des fg_j">
        <h1 className="text">Nous sommes dédiés à l&apos;excellence dans les travaux</h1>
        <p className="des_">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim	</p>

        </div>
    </Container>
    </Col>
  </Row>
  </Carousel.Item>



  </Carousel>
  

       </Styled_>;
};

export default Slider_first;
